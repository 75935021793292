/**
 * Theme for Virgin AU site
 */
import { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';

export const buildThemeVarsAPACAdc = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const adcOrange100 = '#FEEFED'; //color1
  const adcOrange200 = '#F9BCB3'; //color2
  const adcOrange500 = '#F15B46'; //color4
  const adcOrange700 = '#A94031'; //color5
  const adcBlue100 = '#E6ECEF'; //color6
  const adcBlue200 = '#96B3BF'; //color7
  const adcBlue300 = '#257193'; //color8
  const adcBlue400 = '#065679'; //color9
  const adcBlue500 = '#004563'; //color10
  const adcBlue700 = '#370A4C'; //color11
  //secondary
  const adcCream300 = '#F8F5EF'; //color12
  const adcCream400 = '#EEEAE2'; //color13
  const adcCream500 = '#F4F3F0';
  const adcCream600 = '#CFC6BE'; //color14
  const adcCream700 = '#A49384'; //color15
  const adcWhite = '#FFFFFF'; //color16
  const adcGrey100 = '#F8F8F8'; //color17
  const adcGrey200 = '#EDEDED'; //color18
  const adcGrey400 = '#BCBCBC'; //color19
  const adcGrey600 = '#767676'; //color20
  const adcGrey700 = '#616161'; //color21
  const adcGrey800 = '#494949'; //color22
  const adcBlack = '#222222'; //color23
  //accent - Laithwaites Palette
  const adcMaroon500 = '#7A0040'; //color24
  const adcNavy400 = '#203C5D'; //color25
  const adcPink500 = '#E2004D'; //color26
  //accent-Green
  const adcMoss = '#ABC7AB'; //color27
  const adcTeal = '#469D8E'; //color28
  //accent-Gold Gradient
  const adcGoldGradientLight = '#FFE298'; //color29
  const adcGoldGradientDark = '#A48237'; //color30
  const lwPink = '#CF004F';

  const lwThemeVars = buildThemeVarsAPACLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Virgin AU',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-white.svg`,
    },
    colors: {
      primary1: adcNavy400,
      primary2: adcBlue400,
      primary3: adcCream500,
      primary4: adcOrange100,
      primary5: adcOrange200,
      primary6: lwPink,
      primary7: adcOrange700,
      primary8: adcBlue100,
      primary9: adcBlue200,
      primary10: adcBlue300,
      primary11: adcBlue400,
      primary12: adcBlue500,
      primary13: adcBlue700,
      secondary1: adcCream300,
      secondary2: adcCream400,
      secondary3: adcCream600,
      secondary4: adcCream700,
      secondary5: adcWhite,
      secondary6: adcGrey100,
      secondary7: adcGrey200,
      secondary8: adcGrey400,
      secondary9: adcGrey600,
      secondary10: adcGrey700,
      secondary11: adcGrey800,
      secondary12: adcBlack,
      accentMaroon500: adcMaroon500,
      accentNavy400: adcNavy400,
      accentPink500: adcPink500,
      accentMoss: adcMoss,
      accentTeal: adcTeal,
      accentGoldGradientLight: adcGoldGradientLight,
      accentGoldGradientDark: adcGoldGradientDark,
      textPrimaryBg: adcOrange500,

      //specific colour definitions
      btnsPrimaryBg: adcNavy400,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: adcOrange700,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: adcOrange700,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.white,
      btnsTertiaryColor: vars.colors.black,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: vars.colors.white,
      btnsTertiaryHoverColor: vars.colors.black,

      footerPrimaryBg: adcBlue500,
      footerdrinkaware: vars.colors.white,

      bgColors: {
        // adcOrange: adcOrange500,
        adcOrange: adcNavy400,
        adcBlue: adcBlue400,
        adcCream: adcCream400,
        adcWhite: adcWhite,
        adcBlack: adcBlack,
      },
    },
    fonts: {
      familyPrimary: lwThemeVars.fonts.noto,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: lwThemeVars.fonts.oswald,
      headerMainNavTitle: lwThemeVars.fonts.noto,
      headerMainNavSubTitle: lwThemeVars.fonts.roboto,
    },
  });
};

export const buildThemeMixinsAPACAdc = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsAPACLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize45};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize64};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize34};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing2};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
        letter-spacing: ${vars.fonts.fontLetterSpacing2};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize30};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing2};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize34};
        letter-spacing: ${vars.fonts.fontLetterSpacing2};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize28};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing10};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize26};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight12};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight14};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
  });
};

export const buildThemeWidgetStylesAPACAdc = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      headerLogo: `
      img {
         max-width: 13em;
      }`,
    },
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view {
        .ais-Hits-list {
          .ais-Hits-item {
            .hit-content {
              .title {
                font-family: ${vars.fonts.familyPrimary};
              }
            }
            .addToCart-container { 
              .price-section {
                .sku-price {
                  font-weight: ${vars.fonts.weightSemiBold};
                  font-family: ${vars.fonts.familyPrimary};
                  .original-price-bottle { 
                    font-family: ${vars.fonts.familyPrimary};
                  }
                  .vpp-price-section {
                    font-family: ${vars.fonts.familySecondary};
                  }
                }
              }
            }
          }
        }
      }
      `,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familySecondary};
      p {
        font-family: ${vars.fonts.familySecondary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white};
        .wrapper {
          .copyrights {
            p {
              color: ${vars.colors.white};
              a {
                color: ${vars.colors.white};
              }
            }
          }
          .brand img {
              max-width: 13em;
          }
          ${vars.breakpoints.lg} {
            .bedrinkaware-group {
              justify-content: flex-start;
            }
          }
        }
      }
      .footer-nav-links {
        background: ${vars.colors.secondary1};
        box-shadow: 0 0 0 100vmax ${vars.colors.secondary1};
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            font-family: ${vars.fonts.familySecondary};
            letter-spacing: 2px;
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
        }
  `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACAdc, buildThemeMixinsAPACAdc, buildThemeWidgetStylesAPACAdc);

export default createTheme;
