import { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeVarsAPACAdc, buildThemeMixinsAPACAdc, buildThemeWidgetStylesAPACAdc } from 'dw-apac-adc/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => {
      return merge({}, buildThemeVarsAPACAdc(vars));
    },
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
      return buildThemeMixinsAPACAdc(vars);
    },
    (vars: Omit<Theme, 'widgets'>) => {
      return buildThemeWidgetStylesAPACAdc(vars);
    },
  );

export default createTheme;
